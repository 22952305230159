.refunds {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  height: 600px;
  margin: auto;
}

.refunds > h1, .refunds > h2 {
  text-align: center !important;
}

.refunds > p {
  text-indent: 2em;
}



.refunds-contact {
  text-align: center;
}