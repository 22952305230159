.hello-author {
  margin-bottom: 0;
}

.input-form {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: auto;
}

.uploadContainer {
  margin-top: 30px;
  margin-bottom: 0px;
  display: flex;
}

.photo-requirement {
  margin-top: 0px;
  background-color: pink;
  width: 110px;
  padding: 5px;
  border-radius: 5px;
}

.uploadImage {
  width: 200px;
}

.input-form input {
  margin-bottom: 20px;
}

.input-form .write-input-title {
  width: 300px;
}

.styling-buttons {
  padding: 0;
  margin: 0;
  padding-bottom: 10px;
}

.styling-buttons>button {
  width: 20px;
  text-align: center;
  padding: 0;
  margin: 2px;
}

.public-DraftEditor-content {
  min-height: 400px;
  border: solid 1px black;
}

.input-form .write-submit {
  margin: 20px auto;
  width: 70px;
}