.login {
    display: flex;
    align-content: center;
    height: 500px;
}

.login-form {
    width: 250px;
    margin: auto;
    align-items: center;
}

.login-form input {
    width: 250px;
}

.login-form>button {
    margin: 20px auto auto auto;
}

.leaf-gif {
    margin: 100px auto auto auto;
    height: 400px;
}