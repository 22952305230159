.admin-page {
  width: 80%;
  margin: auto;
  text-align: center;
}

.admin {
  margin-top: 60px;
  text-align: center;
}

.navigation-section {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.navigation-section a {
  margin: 5px;
}

.loading {
  margin: auto !important;
  text-align: center;
}

.admin-users {
  margin-top: 60px;
}

.users {
  list-style-type: none;
  padding-left: 0;
}

.account label {
  font-weight: 500;
  margin: 10px;
}

.account {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message {
  background: lightgreen;
  padding: 10px;
  border-radius: 10px;
}