.register {
  display: flex;
  align-content: center;
  height: 500px;
}

.register-form {
  width: 250px;
  margin: auto;
  align-items: center;
}

.register-form input {
  width: 242px;
}

.register-form>button {
  margin: 20px auto auto auto;
}

.createdUser {
  background-color: lightgreen;
  position: absolute;
  border-radius: 10px;
  margin: 10px;
  top: 200px;
  left: 45%;
}