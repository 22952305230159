.search {
  display: flex;
  margin: 20px;
  font-size: 24px;
}

.search-input {
  width: 220px;
}

.fa-search {
  padding-right: 10px;
}

.blog-picture {
  background-size: cover;
  width: 100%;
  object-fit: contain;
}

.blog-section {
  width: 60%;
  margin: auto;
  padding-top: 30px;
  /* padding-bottom: 30px; */
}

.blog-intro {
  padding-bottom: 40px;
  text-indent: 2em;
}

.blog-posts {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* height: 700px;
  overflow: auto; */
}

.blog-post {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  border: 1px solid lightgray;
  border-radius: 20px;
  width: 45%;
  justify-content: space-between;
}

.blog-post-picture {
  width: 220px !important;
  height: 200px;
  border-radius: 20px;
  margin: auto;
}

.blog-post-title {
  margin: 0;
}

.blog-post-author {
  margin: 0;
}

.blog-date {
  margin: 0;
}



.blog-faded {
  padding-left: 40px;
  padding-right: 40px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  color: black;
  border-radius: 20px;
}

.blog-description {
  margin-top: 10px;
}

.blog-description p {
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {
  .blog-section {
    width: 80%;
  }

  .blog-posts {
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .blog-post {
    width: 100%;
  }
}