

.about-picture {
  background-size: cover;
  width: 100%;
  object-fit: contain;
}

.about-section {
  width: 60%;
  text-indent: 2em;
  margin: 50px auto;
}

.about div img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 5px green;
}

.vision {
  text-align: center;
}

.stephanie {
  display: flex;
  margin-top: 50px;
  align-items: center;
}

.chantel {
  display: flex;
  margin-top: 100px;
  align-items: center;
  margin-bottom: 80px !important;
}

.bio {
  margin: 0 0 20px 30px;
}

.bio2 {
  margin: 0 30px 0 0;
}

.chantel-top {
  display: none;
}

@media (max-width: 768px) {
  .about-section {
    width: 80%;
    margin: 30px auto;
  }

  .about div img {
    width: 150px;
    height: 150px;
    margin: auto;
  }

  .bio, .bio2 {
    margin: 0;
  }

  .stephanie, .chantel {
    flex-direction: column;
  }

  .stephanie-name {
    margin-left: 30px;
  }

  .chantel-name {
    margin-left: 30px;
  }

  .chantel-top {
    display: block;
  }

  .chantel-bottom {
    display: none;
  }


}